@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --primary: #1b202a;
  --secondary: #252b36;
  --input: #2c3342;
  --text: #ffffffbf;
  --border: rgba(255, 255, 255, 0.251);
  --danger: #e00000;
  --success: #3cd2a5;
}
html {
  height: 100%;
  background-color: #1d2835;
  background-image: linear-gradient(
    180deg,
    rgba(50, 70, 80, 0.9) 0%,
    #0d101b 100%
  );
}
html::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 110vh;
  z-index: -5;
  background: linear-gradient(180deg, rgba(50, 70, 80, 0.9) 0%, #0d101b 100%);
}
html::after {
  content: "";
  position: fixed;
  top: 0;
  height: 110vh;
  left: 0;
  right: 0;
  z-index: -10;
  background-color: #1d2835;
  background-image: linear-gradient(
    180deg,
    rgba(50, 70, 80, 0.9) 0%,
    #0d101b 100%
  );
  background-image: url("./assets/images/body-img.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: initial;
  height: 100%;
  transition: background 0.2s linear;
  background-size: cover;
}
body {
  background-color: var(--primary);
  margin: 0;
  color: var(--text);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 12px;
  box-sizing: border-box;
}
body {
  background: none;
  position: relative;
  min-height: 100vh;
  z-index: 9999;
  margin: 0;
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-attachment: initial;
  /* background-color: transparent;
  background-image: var(--#{$prefix}body-bg-image);
  background-size: var(--#{$prefix}body-bg-image-size); */
  background-repeat: repeat;
  background-attachment: initial;
  z-index: -5;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 25px;
}
@media (min-width: 640px) {
  .container {
    max-width: 520px;
  }
}
.box {
  max-width: 520px;
  margin: 0 auto 0;
  border: 1px solid var(--border);
  padding: 36px 28px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 440px) {
  .box {
    margin: 32px auto 0;
    padding: 24px;
  }
}
.title {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 12px;
  font-weight: bold;
}
.info {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  margin-bottom: 24px;
}

.input-box {
  display: flex;
  position: relative;
  margin-bottom: 44px;
}
input {
  background-color: transparent;
  border: 1px solid var(--border);
  outline: none;
  height: 22px;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 12px;
  width: 100%;
}
input.is-valid {
  border-color: var(--success);
}
input.is-valid:focus {
  border-color: #4cf4c2;
  box-shadow: 0 0 0 0.25rem rgba(15, 224, 0, 0.25);
}
input.is-invalid {
  border-color: var(--danger);
}
input.is-invalid:focus {
  border-color: #ff6060;
  box-shadow: 0 0 0 0.25rem rgba(224, 0, 0, 0.25);
}
.input-msg {
  position: absolute;
  bottom: -46px;
}
.input-msg.is-valid {
  color: var(--success);
}
.input-msg.is-invalid {
  color: var(--danger);
}
input:focus {
  box-shadow: 0 0 0 0.25rem var(--border);
  border-color: rgba(255, 255, 255, 0.5);
}
button:disabled {
  user-select: none;
  cursor: not-allowed;
}
.primary-button {
  background-color: var(--input);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid var(--border);
  outline: none;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px 22px;
  height: 64px;
}
.primary-button:hover {
  border-color: rgba(255, 255, 255, 0.5);
  background-color: var(--primary);
}
.primary-button:disabled {
  background-color: #1b202a !important;
  border-color: #1b202a !important;
  color: #70737a;
}

.QR-code-svg {
  margin: 0 auto;
}
.QR-card-input {
  width: 100%;
  margin-top: 24px;
  position: relative;
  display: flex;
}
.QR-card-input input {
  width: 100%;
  cursor: pointer;
  font-size: 13px;
}
.QR-card-input button {
  position: absolute;
  top: 6px;
  right: 6px;
  outline: none;
  background-color: var(--input);
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
  border: none;
}
.banner {
  height: 224px;
  margin: 0 auto 24px;
  display: flex;
}
.wallet-btn {
  margin-top: 44px;
}
.loader {
  border: 3px solid var(--border); /* Light grey */
  border-top: 3px solid var(--success); /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pmb-address {
  margin-top: 16px;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: rgba(255, 255, 255, 0.25);
  caption-side: bottom;
  border-collapse: collapse;
  margin-top: 16px;
  font-size: 12px;
}
.table > thead {
  vertical-align: bottom;
}
th {
  white-space: nowrap;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table > :not(caption) > * > * {
  padding: 1rem 1rem;
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-table-color))
  );
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table > tbody {
  vertical-align: inherit;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.transaction-table {
  margin: 44px 0;
}
.transaction-table h3 {
  text-align: center;
  font-size: 24px;
}
.status {
  border: 1px solid var(--input);
  padding: 4px;
  border-radius: 4px;
  background-color: #2c3342;
}
.status.success {
  border-color: var(--success);
  color: #fff;
  background-color: #84a098;
}

.table-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.table a {
  color: var(--success);
}
h3 {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 640px) {
  .table-container {
    max-width: 920px;
    margin-top: 25px;
  }
  .transaction-table {
    margin: 102px 0;
  }
  h3 {
    font-size: 20px;
    line-height: 32px;
  }
  .transaction-table h3 {
    font-size: 32px;
  }
  .QR-card-input input {
    font-size: 16px;
  }
  .info {
    font-size: 20px;
    line-height: 32px;
  }
  .pmb-address {
    margin-top: 32px;
  }
  .table{
    font-size: 16px;
  }
}
